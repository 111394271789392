/** @jsx jsx */
import { Box, Container, Grid, jsx } from 'theme-ui'
import { FC, ReactNode } from 'react'
import Layout from '~/components/layout'
import HeadingV2 from '~/componentsV2/heading'

type Props = {
  main: ReactNode
  side: ReactNode
  title: string
  totalItems: number
  sub: number
}

const CheckoutFlowLayout: FC<Props> = ({ main, side, title, totalItems }) =>
  (
    <Layout headerVariant="default" footer={false}>
      <Container
        sx={{
          my: ['0', 'l'],
          py: ['18px!important', 'initial!important'],
          px: ['24px', '24px'],
        }}
        variant="cart"
      >
        <Grid columns={[1, null, '2.5fr 1fr']}>
          <Box
            as="section"
            sx={{
              pr: [0, null, 25],
              borderRight: [null, null, t => `1px solid ${t.colors.border}`],
            }}
          >
            <HeadingV2
              level="1"
              sx={{
                textAlign: ['center', 'left'],
                textTransform: ['uppercase'],
                fontSize: ['22px!important', '33px!important'],
                display: ['flex'],
                alignItems: 'center',
                justifyContent: ['center', 'space-between'],
              }}
            >
              {title}
              <Box
                sx={{
                  fontSize: '15px',
                  fontFamily: 'Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont',
                  display: ['none', 'block'],
                }}
              >
                {totalItems} {totalItems === 1 ? 'item in cart' : 'items in cart'}
              </Box>
            </HeadingV2>
            {main}
          </Box>
          <Box
            as="section"
            sx={{
              pl: [0, null, 57],
            }}
          >
            {side}
          </Box>
        </Grid>
      </Container>
    </Layout>
  ) as JSX.Element

export default CheckoutFlowLayout
